import { render, staticRenderFns } from "./UiButton.vue?vue&type=template&id=5ad2736f"
import script from "./UiButton.vue?vue&type=script&setup=true&lang=ts"
export * from "./UiButton.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./UiButton.vue?vue&type=style&index=0&id=5ad2736f&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../work/modules/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiButtonInner: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/CANYON/CANYON_Deploy_Prod@2/components/ui/UiButtonInner.vue').default,UiLink: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/CANYON/CANYON_Deploy_Prod@2/components/ui/UiLink.vue').default})
