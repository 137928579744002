import Vue from 'vue';
import { abPlugin } from '@webplatform/asbis-ui';
import {
  BlogSectionSimple,
  CardSectionApps,
  CardSectionAwards,
  CardSectionCatalog,
  CardSectionFlags,
  CardSectionGrid,
  CardSectionShops,
  CardSectionSimple,
  DocumentSectionSimple,
  FaqSectionSimple,
  LogoCloudSectionSimple,
  LogoCloudSectionWithFeatures,
  TeamSectionSimple,
  TestimonialSectionSlider,
  VideoSectionSimple,
  FeatureSectionArticles,
  FeatureSectionCarousel,
  FeatureSectionColumns,
  FeatureSectionInfoBlocks,
  FeatureSectionRecommended,
  FeatureSectionSimple,
  FeatureSectionSimpleList,
  FeatureSectionTextImage,
  HeaderSectionBackground,
  HeaderSectionImage,
  HeaderSectionSimple,
  HeaderSectionWithBanner,
  ContentSectionSeo,
  VideoSectionTabs,
  CardSectionCategories,
  FeatureSectionBanner,
  FeatureSectionBullets,
  FeatureSectionCarouselButton,
  FeatureSectionSmallBanners,
  CardSectionSmallBlocks,
  BlogSectionTextImage,
  ContentSectionTips,
  FeatureSectionPoints,
  FeatureSectionSimpleListVariation,
  HeroSection,
  ContentSectionFootnote,
  CtaSectionBanner,
  FeatureSectionBannerVariation,
  FeatureSectionBenefits,
  FeatureSectionPartners,
  HeroSectionBanner,
} from '@webplatform/asbis-ui/dist/sections';

import '@webplatform/asbis-ui/dist/assets/scss/index.scss';
import '@webplatform/asbis-ui/dist/assets/themes/canyon/theme.scss';
import '@webplatform/asbis-ui/dist/assets/themes/canyon-gaming/theme.scss';

Vue.component('BlogSectionSimple', BlogSectionSimple);
Vue.component('CardSectionApps', CardSectionApps);
Vue.component('CardSectionAwards', CardSectionAwards);
Vue.component('CardSectionCatalog', CardSectionCatalog);
Vue.component('CardSectionFlags', CardSectionFlags);
Vue.component('CardSectionGrid', CardSectionGrid);
Vue.component('CardSectionShops', CardSectionShops);
Vue.component('CardSectionSimple', CardSectionSimple);
Vue.component('DocumentSectionSimple', DocumentSectionSimple);
Vue.component('FaqSectionSimple', FaqSectionSimple);
Vue.component('LogoCloudSectionSimple', LogoCloudSectionSimple);
Vue.component('LogoCloudSectionWithFeatures', LogoCloudSectionWithFeatures);
Vue.component('TeamSectionSimple', TeamSectionSimple);
Vue.component('TestimonialSectionSlider', TestimonialSectionSlider);
Vue.component('VideoSectionSimple', VideoSectionSimple);
Vue.component('FeatureSectionArticles', FeatureSectionArticles);
Vue.component('FeatureSectionCarousel', FeatureSectionCarousel);
Vue.component('FeatureSectionColumns', FeatureSectionColumns);
Vue.component('FeatureSectionInfoBlocks', FeatureSectionInfoBlocks);
Vue.component('FeatureSectionRecommended', FeatureSectionRecommended);
Vue.component('FeatureSectionSimple', FeatureSectionSimple);
Vue.component('FeatureSectionSimpleList', FeatureSectionSimpleList);
Vue.component('FeatureSectionTextImage', FeatureSectionTextImage);
Vue.component('HeaderSectionBackground', HeaderSectionBackground);
Vue.component('HeaderSectionImage', HeaderSectionImage);
Vue.component('HeaderSectionSimple', HeaderSectionSimple);
Vue.component('HeaderSectionWithBanner', HeaderSectionWithBanner);
Vue.component('ContentSectionSeo', ContentSectionSeo);
Vue.component('VideoSectionTabs', VideoSectionTabs);
Vue.component('CardSectionCategories', CardSectionCategories);
Vue.component('FeatureSectionBanner', FeatureSectionBanner);
Vue.component('FeatureSectionBullets', FeatureSectionBullets);
Vue.component('FeatureSectionCarouselButton', FeatureSectionCarouselButton);
Vue.component('FeatureSectionSmallBanners', FeatureSectionSmallBanners);
Vue.component('CardSectionSmallBlocks', CardSectionSmallBlocks);
Vue.component('BlogSectionTextImage', BlogSectionTextImage);
Vue.component('ContentSectionTips', ContentSectionTips);
Vue.component('FeatureSectionPoints', FeatureSectionPoints);
Vue.component(
  'FeatureSectionSimpleListVariation',
  FeatureSectionSimpleListVariation,
);
Vue.component('HeroSection', HeroSection);
Vue.component('ContentSectionFootnote', ContentSectionFootnote);
Vue.component('CtaSectionBanner', CtaSectionBanner);
Vue.component('FeatureSectionBannerVariation', FeatureSectionBannerVariation);
Vue.component('FeatureSectionBenefits', FeatureSectionBenefits);
Vue.component('FeatureSectionPartners', FeatureSectionPartners);
Vue.component('HeroSectionBanner', HeroSectionBanner);

Vue.config.ignoredElements = [
  'swiper-container',
  'swiper-slide',
  'sl-tab',
  'sl-tab-panel',
  'sl-tab-group',
];

// eslint-disable-next-line func-names
export default function ({ $gsap, $ScrollTrigger }) {
  Vue.use(abPlugin, {
    preset: 'canyon',
    components: {
      AbButton: {
        props: {
          multilayered: {
            default: true,
          },
        },
      },
    },
    gsap: $gsap,
    ScrollTrigger: $ScrollTrigger,
  });
}
