import { render, staticRenderFns } from "./AppHeaderMobileMenu.vue?vue&type=template&id=117f9f21"
import script from "./AppHeaderMobileMenu.vue?vue&type=script&setup=true&lang=ts"
export * from "./AppHeaderMobileMenu.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./AppHeaderMobileMenu.vue?vue&type=style&index=0&id=117f9f21&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../work/modules/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiLink: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/CANYON/CANYON_Deploy_Prod@2/components/ui/UiLink.vue').default,UiIcon: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/CANYON/CANYON_Deploy_Prod@2/components/ui/UiIcon.vue').default,LanguageSwitcher: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/CANYON/CANYON_Deploy_Prod@2/components/LanguageSwitcher.vue').default,UiButton: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/CANYON/CANYON_Deploy_Prod@2/components/ui/UiButton.vue').default})
