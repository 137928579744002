const SOCIAL_NAME_REGEX = /^https?:\/\/(?:www\.)?([a-z0-9-]+)\..*$/i;

/* eslint-disable no-shadow */
export const state = () => ({
  config: {},
  GSVMetas: [],
});

export const getters = {
  getGTM(state) {
    return state.config.gtm;
  },

  getDomains(state) {
    return state.config.sites;
  },
  getSocials(state) {
    return state?.config?.social_links?.map?.((social) => ({
      name: social.url.match(SOCIAL_NAME_REGEX)?.[1],
      src: social.url,
    }));
  },
  getGSVMetas(state) {
    return state.GSVMetas;
  },
  getFavicon(state) {
    return state.config.favicon;
  },
};

export const mutations = {
  setConfig(state, config) {
    // eslint-disable-next-line no-param-reassign
    state.config = config;
  },
  setGSVMetas(state, meta) {
    state.GSVMetas.push(meta);
  },
};

export const actions = {
  async load({ commit }, { $api: { siteInfoService } }) {
    try {
      const { data } = await siteInfoService.getSiteInfo();
      commit('setConfig', data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error.response?.data ?? error);
    }
  },
};
