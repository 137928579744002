import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _11a9ee5e = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _3d41981f = () => interopDefault(import('../pages/account-removal-request.vue' /* webpackChunkName: "pages/account-removal-request" */))
const _73fbabbe = () => interopDefault(import('../pages/certificates/index.vue' /* webpackChunkName: "pages/certificates/index" */))
const _d1076d2c = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _331b5709 = () => interopDefault(import('../pages/drivers-and-manuals/index.vue' /* webpackChunkName: "pages/drivers-and-manuals/index" */))
const _6ace7712 = () => interopDefault(import('../pages/e-catalogue.vue' /* webpackChunkName: "pages/e-catalogue" */))
const _41aaeb15 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _3608e163 = () => interopDefault(import('../pages/promo/index.vue' /* webpackChunkName: "pages/promo/index" */))
const _b2d396b2 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _381c61ea = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _45a63c3a = () => interopDefault(import('../pages/search/drivers.vue' /* webpackChunkName: "pages/search/drivers" */))
const _2183f696 = () => interopDefault(import('../pages/search/faqs.vue' /* webpackChunkName: "pages/search/faqs" */))
const _0c1d6c6b = () => interopDefault(import('../pages/search/news.vue' /* webpackChunkName: "pages/search/news" */))
const _48bc7b08 = () => interopDefault(import('../pages/search/products.vue' /* webpackChunkName: "pages/search/products" */))
const _e14f74b6 = () => interopDefault(import('../pages/user-help-desk/index.vue' /* webpackChunkName: "pages/user-help-desk/index" */))
const _2159b748 = () => interopDefault(import('../pages/where-to-buy/index.vue' /* webpackChunkName: "pages/where-to-buy/index" */))
const _47411c6c = () => interopDefault(import('../pages/user-help-desk/faq/index.vue' /* webpackChunkName: "pages/user-help-desk/faq/index" */))
const _3161c923 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _458a7b24 = () => interopDefault(import('../pages/user-help-desk/faq/_slug.vue' /* webpackChunkName: "pages/user-help-desk/faq/_slug" */))
const _72450a76 = () => interopDefault(import('../pages/certificates/_slug.vue' /* webpackChunkName: "pages/certificates/_slug" */))
const _3ff449cd = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _2551f86c = () => interopDefault(import('../pages/product-category/_category/index.vue' /* webpackChunkName: "pages/product-category/_category/index" */))
const _1bd6d1bb = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _3452401b = () => interopDefault(import('../pages/promo/_slug.vue' /* webpackChunkName: "pages/promo/_slug" */))
const _17c36b18 = () => interopDefault(import('../pages/product-category/_category/_subcategory/index.vue' /* webpackChunkName: "pages/product-category/_category/_subcategory/index" */))
const _213816a6 = () => interopDefault(import('../pages/product-category/_category/_subcategory/_subsubcategory/index.vue' /* webpackChunkName: "pages/product-category/_category/_subcategory/_subsubcategory/index" */))
const _6c584944 = () => interopDefault(import('../pages/product-category/_category/_subcategory/_subsubcategory/_subsubsubcategory/index.vue' /* webpackChunkName: "pages/product-category/_category/_subcategory/_subsubcategory/_subsubsubcategory/index" */))
const _2fab27db = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _11a9ee5e,
    name: "about___en"
  }, {
    path: "/about",
    component: _11a9ee5e,
    name: "about___uk"
  }, {
    path: "/about",
    component: _11a9ee5e,
    name: "about___bg"
  }, {
    path: "/about",
    component: _11a9ee5e,
    name: "about___cs"
  }, {
    path: "/about",
    component: _11a9ee5e,
    name: "about___ro"
  }, {
    path: "/about",
    component: _11a9ee5e,
    name: "about___sk"
  }, {
    path: "/about",
    component: _11a9ee5e,
    name: "about___ru"
  }, {
    path: "/account-removal-request",
    component: _3d41981f,
    name: "account-removal-request___en"
  }, {
    path: "/account-removal-request",
    component: _3d41981f,
    name: "account-removal-request___uk"
  }, {
    path: "/account-removal-request",
    component: _3d41981f,
    name: "account-removal-request___bg"
  }, {
    path: "/account-removal-request",
    component: _3d41981f,
    name: "account-removal-request___cs"
  }, {
    path: "/account-removal-request",
    component: _3d41981f,
    name: "account-removal-request___ro"
  }, {
    path: "/account-removal-request",
    component: _3d41981f,
    name: "account-removal-request___sk"
  }, {
    path: "/account-removal-request",
    component: _3d41981f,
    name: "account-removal-request___ru"
  }, {
    path: "/certificates",
    component: _73fbabbe,
    name: "certificates___en"
  }, {
    path: "/certificates",
    component: _73fbabbe,
    name: "certificates___uk"
  }, {
    path: "/certificates",
    component: _73fbabbe,
    name: "certificates___bg"
  }, {
    path: "/certificates",
    component: _73fbabbe,
    name: "certificates___cs"
  }, {
    path: "/certificates",
    component: _73fbabbe,
    name: "certificates___ro"
  }, {
    path: "/certificates",
    component: _73fbabbe,
    name: "certificates___sk"
  }, {
    path: "/certificates",
    component: _73fbabbe,
    name: "certificates___ru"
  }, {
    path: "/contact-us",
    component: _d1076d2c,
    name: "contact-us___en"
  }, {
    path: "/contact-us",
    component: _d1076d2c,
    name: "contact-us___uk"
  }, {
    path: "/contact-us",
    component: _d1076d2c,
    name: "contact-us___bg"
  }, {
    path: "/contact-us",
    component: _d1076d2c,
    name: "contact-us___cs"
  }, {
    path: "/contact-us",
    component: _d1076d2c,
    name: "contact-us___ro"
  }, {
    path: "/contact-us",
    component: _d1076d2c,
    name: "contact-us___sk"
  }, {
    path: "/contact-us",
    component: _d1076d2c,
    name: "contact-us___ru"
  }, {
    path: "/drivers-and-manuals",
    component: _331b5709,
    name: "drivers-and-manuals___en"
  }, {
    path: "/drivers-and-manuals",
    component: _331b5709,
    name: "drivers-and-manuals___uk"
  }, {
    path: "/drivers-and-manuals",
    component: _331b5709,
    name: "drivers-and-manuals___bg"
  }, {
    path: "/drivers-and-manuals",
    component: _331b5709,
    name: "drivers-and-manuals___cs"
  }, {
    path: "/drivers-and-manuals",
    component: _331b5709,
    name: "drivers-and-manuals___ro"
  }, {
    path: "/drivers-and-manuals",
    component: _331b5709,
    name: "drivers-and-manuals___sk"
  }, {
    path: "/drivers-and-manuals",
    component: _331b5709,
    name: "drivers-and-manuals___ru"
  }, {
    path: "/e-catalogue",
    component: _6ace7712,
    name: "e-catalogue___en"
  }, {
    path: "/e-catalogue",
    component: _6ace7712,
    name: "e-catalogue___uk"
  }, {
    path: "/e-catalogue",
    component: _6ace7712,
    name: "e-catalogue___bg"
  }, {
    path: "/e-catalogue",
    component: _6ace7712,
    name: "e-catalogue___cs"
  }, {
    path: "/e-catalogue",
    component: _6ace7712,
    name: "e-catalogue___ro"
  }, {
    path: "/e-catalogue",
    component: _6ace7712,
    name: "e-catalogue___sk"
  }, {
    path: "/e-catalogue",
    component: _6ace7712,
    name: "e-catalogue___ru"
  }, {
    path: "/news",
    component: _41aaeb15,
    name: "news___en"
  }, {
    path: "/news",
    component: _41aaeb15,
    name: "news___uk"
  }, {
    path: "/news",
    component: _41aaeb15,
    name: "news___bg"
  }, {
    path: "/news",
    component: _41aaeb15,
    name: "news___cs"
  }, {
    path: "/news",
    component: _41aaeb15,
    name: "news___ro"
  }, {
    path: "/news",
    component: _41aaeb15,
    name: "news___sk"
  }, {
    path: "/news",
    component: _41aaeb15,
    name: "news___ru"
  }, {
    path: "/promo",
    component: _3608e163,
    name: "promo___en"
  }, {
    path: "/promo",
    component: _3608e163,
    name: "promo___uk"
  }, {
    path: "/promo",
    component: _3608e163,
    name: "promo___bg"
  }, {
    path: "/promo",
    component: _3608e163,
    name: "promo___cs"
  }, {
    path: "/promo",
    component: _3608e163,
    name: "promo___ro"
  }, {
    path: "/promo",
    component: _3608e163,
    name: "promo___sk"
  }, {
    path: "/promo",
    component: _3608e163,
    name: "promo___ru"
  }, {
    path: "/search",
    component: _b2d396b2,
    children: [{
      path: "",
      component: _381c61ea,
      name: "search___en"
    }, {
      path: "drivers",
      component: _45a63c3a,
      name: "search-drivers___en"
    }, {
      path: "faqs",
      component: _2183f696,
      name: "search-faqs___en"
    }, {
      path: "news",
      component: _0c1d6c6b,
      name: "search-news___en"
    }, {
      path: "products",
      component: _48bc7b08,
      name: "search-products___en"
    }]
  }, {
    path: "/search",
    component: _b2d396b2,
    children: [{
      path: "",
      component: _381c61ea,
      name: "search___uk"
    }, {
      path: "drivers",
      component: _45a63c3a,
      name: "search-drivers___uk"
    }, {
      path: "faqs",
      component: _2183f696,
      name: "search-faqs___uk"
    }, {
      path: "news",
      component: _0c1d6c6b,
      name: "search-news___uk"
    }, {
      path: "products",
      component: _48bc7b08,
      name: "search-products___uk"
    }]
  }, {
    path: "/search",
    component: _b2d396b2,
    children: [{
      path: "",
      component: _381c61ea,
      name: "search___bg"
    }, {
      path: "drivers",
      component: _45a63c3a,
      name: "search-drivers___bg"
    }, {
      path: "faqs",
      component: _2183f696,
      name: "search-faqs___bg"
    }, {
      path: "news",
      component: _0c1d6c6b,
      name: "search-news___bg"
    }, {
      path: "products",
      component: _48bc7b08,
      name: "search-products___bg"
    }]
  }, {
    path: "/search",
    component: _b2d396b2,
    children: [{
      path: "",
      component: _381c61ea,
      name: "search___cs"
    }, {
      path: "drivers",
      component: _45a63c3a,
      name: "search-drivers___cs"
    }, {
      path: "faqs",
      component: _2183f696,
      name: "search-faqs___cs"
    }, {
      path: "news",
      component: _0c1d6c6b,
      name: "search-news___cs"
    }, {
      path: "products",
      component: _48bc7b08,
      name: "search-products___cs"
    }]
  }, {
    path: "/search",
    component: _b2d396b2,
    children: [{
      path: "",
      component: _381c61ea,
      name: "search___ro"
    }, {
      path: "drivers",
      component: _45a63c3a,
      name: "search-drivers___ro"
    }, {
      path: "faqs",
      component: _2183f696,
      name: "search-faqs___ro"
    }, {
      path: "news",
      component: _0c1d6c6b,
      name: "search-news___ro"
    }, {
      path: "products",
      component: _48bc7b08,
      name: "search-products___ro"
    }]
  }, {
    path: "/search",
    component: _b2d396b2,
    children: [{
      path: "",
      component: _381c61ea,
      name: "search___sk"
    }, {
      path: "drivers",
      component: _45a63c3a,
      name: "search-drivers___sk"
    }, {
      path: "faqs",
      component: _2183f696,
      name: "search-faqs___sk"
    }, {
      path: "news",
      component: _0c1d6c6b,
      name: "search-news___sk"
    }, {
      path: "products",
      component: _48bc7b08,
      name: "search-products___sk"
    }]
  }, {
    path: "/search",
    component: _b2d396b2,
    children: [{
      path: "",
      component: _381c61ea,
      name: "search___ru"
    }, {
      path: "drivers",
      component: _45a63c3a,
      name: "search-drivers___ru"
    }, {
      path: "faqs",
      component: _2183f696,
      name: "search-faqs___ru"
    }, {
      path: "news",
      component: _0c1d6c6b,
      name: "search-news___ru"
    }, {
      path: "products",
      component: _48bc7b08,
      name: "search-products___ru"
    }]
  }, {
    path: "/user-help-desk",
    component: _e14f74b6,
    name: "user-help-desk___en"
  }, {
    path: "/user-help-desk",
    component: _e14f74b6,
    name: "user-help-desk___uk"
  }, {
    path: "/user-help-desk",
    component: _e14f74b6,
    name: "user-help-desk___bg"
  }, {
    path: "/user-help-desk",
    component: _e14f74b6,
    name: "user-help-desk___cs"
  }, {
    path: "/user-help-desk",
    component: _e14f74b6,
    name: "user-help-desk___ro"
  }, {
    path: "/user-help-desk",
    component: _e14f74b6,
    name: "user-help-desk___sk"
  }, {
    path: "/user-help-desk",
    component: _e14f74b6,
    name: "user-help-desk___ru"
  }, {
    path: "/where-to-buy",
    component: _2159b748,
    name: "where-to-buy___en"
  }, {
    path: "/where-to-buy",
    component: _2159b748,
    name: "where-to-buy___uk"
  }, {
    path: "/where-to-buy",
    component: _2159b748,
    name: "where-to-buy___bg"
  }, {
    path: "/where-to-buy",
    component: _2159b748,
    name: "where-to-buy___cs"
  }, {
    path: "/where-to-buy",
    component: _2159b748,
    name: "where-to-buy___ro"
  }, {
    path: "/where-to-buy",
    component: _2159b748,
    name: "where-to-buy___sk"
  }, {
    path: "/where-to-buy",
    component: _2159b748,
    name: "where-to-buy___ru"
  }, {
    path: "/user-help-desk/faq",
    component: _47411c6c,
    name: "user-help-desk-faq___en"
  }, {
    path: "/user-help-desk/faq",
    component: _47411c6c,
    name: "user-help-desk-faq___uk"
  }, {
    path: "/user-help-desk/faq",
    component: _47411c6c,
    name: "user-help-desk-faq___bg"
  }, {
    path: "/user-help-desk/faq",
    component: _47411c6c,
    name: "user-help-desk-faq___cs"
  }, {
    path: "/user-help-desk/faq",
    component: _47411c6c,
    name: "user-help-desk-faq___ro"
  }, {
    path: "/user-help-desk/faq",
    component: _47411c6c,
    name: "user-help-desk-faq___sk"
  }, {
    path: "/user-help-desk/faq",
    component: _47411c6c,
    name: "user-help-desk-faq___ru"
  }, {
    path: "/",
    component: _3161c923,
    name: "index___en"
  }, {
    path: "/",
    component: _3161c923,
    name: "index___uk"
  }, {
    path: "/",
    component: _3161c923,
    name: "index___bg"
  }, {
    path: "/",
    component: _3161c923,
    name: "index___cs"
  }, {
    path: "/",
    component: _3161c923,
    name: "index___ro"
  }, {
    path: "/",
    component: _3161c923,
    name: "index___sk"
  }, {
    path: "/",
    component: _3161c923,
    name: "index___ru"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _458a7b24,
    name: "user-help-desk-faq-slug___en"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _458a7b24,
    name: "user-help-desk-faq-slug___uk"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _458a7b24,
    name: "user-help-desk-faq-slug___bg"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _458a7b24,
    name: "user-help-desk-faq-slug___cs"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _458a7b24,
    name: "user-help-desk-faq-slug___ro"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _458a7b24,
    name: "user-help-desk-faq-slug___sk"
  }, {
    path: "/user-help-desk/faq/:slug?",
    component: _458a7b24,
    name: "user-help-desk-faq-slug___ru"
  }, {
    path: "/certificates/:slug",
    component: _72450a76,
    name: "certificates-slug___en"
  }, {
    path: "/certificates/:slug",
    component: _72450a76,
    name: "certificates-slug___uk"
  }, {
    path: "/certificates/:slug",
    component: _72450a76,
    name: "certificates-slug___bg"
  }, {
    path: "/certificates/:slug",
    component: _72450a76,
    name: "certificates-slug___cs"
  }, {
    path: "/certificates/:slug",
    component: _72450a76,
    name: "certificates-slug___ro"
  }, {
    path: "/certificates/:slug",
    component: _72450a76,
    name: "certificates-slug___sk"
  }, {
    path: "/certificates/:slug",
    component: _72450a76,
    name: "certificates-slug___ru"
  }, {
    path: "/news/:slug",
    component: _3ff449cd,
    name: "news-slug___en"
  }, {
    path: "/news/:slug",
    component: _3ff449cd,
    name: "news-slug___uk"
  }, {
    path: "/news/:slug",
    component: _3ff449cd,
    name: "news-slug___bg"
  }, {
    path: "/news/:slug",
    component: _3ff449cd,
    name: "news-slug___cs"
  }, {
    path: "/news/:slug",
    component: _3ff449cd,
    name: "news-slug___ro"
  }, {
    path: "/news/:slug",
    component: _3ff449cd,
    name: "news-slug___sk"
  }, {
    path: "/news/:slug",
    component: _3ff449cd,
    name: "news-slug___ru"
  }, {
    path: "/product-category/:category",
    component: _2551f86c,
    name: "product-category-category___en"
  }, {
    path: "/product-category/:category",
    component: _2551f86c,
    name: "product-category-category___uk"
  }, {
    path: "/product-category/:category",
    component: _2551f86c,
    name: "product-category-category___bg"
  }, {
    path: "/product-category/:category",
    component: _2551f86c,
    name: "product-category-category___cs"
  }, {
    path: "/product-category/:category",
    component: _2551f86c,
    name: "product-category-category___ro"
  }, {
    path: "/product-category/:category",
    component: _2551f86c,
    name: "product-category-category___sk"
  }, {
    path: "/product-category/:category",
    component: _2551f86c,
    name: "product-category-category___ru"
  }, {
    path: "/product/:slug?",
    component: _1bd6d1bb,
    name: "product-slug___en"
  }, {
    path: "/product/:slug?",
    component: _1bd6d1bb,
    name: "product-slug___uk"
  }, {
    path: "/product/:slug?",
    component: _1bd6d1bb,
    name: "product-slug___bg"
  }, {
    path: "/product/:slug?",
    component: _1bd6d1bb,
    name: "product-slug___cs"
  }, {
    path: "/product/:slug?",
    component: _1bd6d1bb,
    name: "product-slug___ro"
  }, {
    path: "/product/:slug?",
    component: _1bd6d1bb,
    name: "product-slug___sk"
  }, {
    path: "/product/:slug?",
    component: _1bd6d1bb,
    name: "product-slug___ru"
  }, {
    path: "/promo/:slug",
    component: _3452401b,
    name: "promo-slug___en"
  }, {
    path: "/promo/:slug",
    component: _3452401b,
    name: "promo-slug___uk"
  }, {
    path: "/promo/:slug",
    component: _3452401b,
    name: "promo-slug___bg"
  }, {
    path: "/promo/:slug",
    component: _3452401b,
    name: "promo-slug___cs"
  }, {
    path: "/promo/:slug",
    component: _3452401b,
    name: "promo-slug___ro"
  }, {
    path: "/promo/:slug",
    component: _3452401b,
    name: "promo-slug___sk"
  }, {
    path: "/promo/:slug",
    component: _3452401b,
    name: "promo-slug___ru"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _17c36b18,
    name: "product-category-category-subcategory___en"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _17c36b18,
    name: "product-category-category-subcategory___uk"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _17c36b18,
    name: "product-category-category-subcategory___bg"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _17c36b18,
    name: "product-category-category-subcategory___cs"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _17c36b18,
    name: "product-category-category-subcategory___ro"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _17c36b18,
    name: "product-category-category-subcategory___sk"
  }, {
    path: "/product-category/:category?/:subcategory",
    component: _17c36b18,
    name: "product-category-category-subcategory___ru"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _213816a6,
    name: "product-category-category-subcategory-subsubcategory___en"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _213816a6,
    name: "product-category-category-subcategory-subsubcategory___uk"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _213816a6,
    name: "product-category-category-subcategory-subsubcategory___bg"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _213816a6,
    name: "product-category-category-subcategory-subsubcategory___cs"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _213816a6,
    name: "product-category-category-subcategory-subsubcategory___ro"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _213816a6,
    name: "product-category-category-subcategory-subsubcategory___sk"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory",
    component: _213816a6,
    name: "product-category-category-subcategory-subsubcategory___ru"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _6c584944,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___en"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _6c584944,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___uk"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _6c584944,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___bg"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _6c584944,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___cs"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _6c584944,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___ro"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _6c584944,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___sk"
  }, {
    path: "/product-category/:category?/:subcategory?/:subsubcategory/:subsubsubcategory",
    component: _6c584944,
    name: "product-category-category-subcategory-subsubcategory-subsubsubcategory___ru"
  }, {
    path: "/:slug",
    component: _2fab27db,
    name: "slug___en"
  }, {
    path: "/:slug",
    component: _2fab27db,
    name: "slug___uk"
  }, {
    path: "/:slug",
    component: _2fab27db,
    name: "slug___bg"
  }, {
    path: "/:slug",
    component: _2fab27db,
    name: "slug___cs"
  }, {
    path: "/:slug",
    component: _2fab27db,
    name: "slug___ro"
  }, {
    path: "/:slug",
    component: _2fab27db,
    name: "slug___sk"
  }, {
    path: "/:slug",
    component: _2fab27db,
    name: "slug___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
